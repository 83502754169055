<template>
  <section class="overlay-print text-center">
    <div class="content white">
      <h4>{{ $t("packetReturnShipment.printing") }}</h4>

      <div class="flex-group">
        <div v-for="(printType, index) in printables" :key="index" class="item">
          <h1>
            {{ $t(`packetReturnShipment.printables.${printType}.title`) }}
          </h1>
          <img
            :src="`/printables/${printType}.jpg`"
            :alt="$t(`packetReturnShipment.printables.${printType}.title`)"
          />
          <p>
            {{ $t(`packetReturnShipment.printables.${printType}.description`) }}
          </p>
        </div>
      </div>
    </div>

    <Errors :errors="errors" />

    <div class="buttons container">
      <PrintButton
        v-for="(printType, index) in printables"
        :key="index"
        :printType="printType"
      />

      <a href="#" class="btn btn-primary btn-green" @click.prevent="finish">{{
        $t("packetC2Creceive.finish")
      }}</a>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { getParams } from "@/mixins/platform-params.js";
import Errors from "@/components/Errors";
import PrintButton from "@/components/PrintButton";

export default {
  name: "OverlayPrint",
  components: { Errors, PrintButton },
  mixins: [getParams],
  computed: {
    ...mapState(["errors"]),
    ...mapState("packet", ["printables"])
  },

  methods: {
    finish() {
      this.$router.replace({
        name: "packet-search",
        query: {
          platform: this.platform,
          device: this.device
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.overlay-print {
  @include overlay;
  background: rgba(0, 0, 0, 0.95);

  .content {
    h4 {
      margin: 32px 0 20px;
    }

    .flex-group {
      display: flex;
      justify-content: center;

      img {
        height: 121px;
      }

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 30px;

        &:last-child {
          margin-right: 0;
        }

        h1 {
          font-size: 1.375rem;
          text-transform: uppercase;
          margin-bottom: 13px;
        }

        p {
          margin-top: 5px;
          max-width: 130px;
        }
      }
    }
  }

  .buttons {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px 16px;

    a {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
