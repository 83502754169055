<template>
  <a href="#" @click.prevent="handlePrint" class="btn btn-primary btn-white">
    {{ text }}
  </a>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "PrintButton",
  props: {
    printType: {
      type: String,
      required: true
    },
    short: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      text: ""
    };
  },

  mounted() {
    const autoPrinted = this.autoPrints.includes(this.printType);

    this.updatePrintText(autoPrinted);
  },

  computed: {
    ...mapState("packet", ["packetBarcode"]),
    ...mapGetters("packet", ["autoPrints"])
  },

  methods: {
    ...mapActions("packet", ["print"]),

    async handlePrint() {
      const response = await this.print({
        type: this.printType,
        barcode: this.packetBarcode
      });
      if (!response) return;

      this.updatePrintText(true);
    },

    updatePrintText(printed = false) {
      const actionBase = printed ? "reprint" : "print";
      const action = this.short ? `${actionBase}-short` : actionBase;

      this.text = this.$t(`prints.${this.printType}.${action}`);
    }
  }
};
</script>
